const BaseURL = 'https://app-end.taller24e.org/api';

const sitekey ="6LdChZwUAAAAAIh32DATM4AHCVIxuEqEygIhrYVV";
const multicurso = false;
const paidMode = false;
const userCanRegister = false;

const logos = {
  logoTopbar: '24eplus_horizontal_color.png',
  logoLogin: '24eplus_horizontal_color.png',
  backgroundLogin: 'background.jpg',
  logo_inicio_courses: '24eplus_horizontal_color.png',
}
const textos = {
  textoLogoLogin:'',
  bienvenido: 'Bienvenido',
  tituloDashboard: "Taller 24E Plus",
  textoDashboard: `El Taller 24E Plus Planificación estratégica, Marketing y Ventas está dirigido a personas que tengan una idea avanzada de negocio, un emprendimiento, una empresa en su etapa inicial, un negocio o un comercio.`,
  bienvenidaRegistro: `¡Bienvenido!`,
}

const auxpiciadores = [
  {
    logo: 'emprender-futuro-logo.svg',
    width: 159,
    height: 48
  },
  {
    logo: 'logo-ceci-4.png',
    width: 159,
    height: 48
  },
  {
    logo: 'logo-ceci-1.png',
    width: 159,
    height: 48
  },
  {
    logo: 'logo-ceci-2.png',
    width: 159,
    height: 48
  },
  {
    logo: 'logo-ceci-3.png',
    width: 159,
    height: 48
  }
]

const logosLogin = [
  {
    logo: 'emprender-futuro-logo.svg',
    width: 153,
    height: 46
  }
]

export {
  BaseURL,
  logos,
  textos,
  auxpiciadores,
  logosLogin,
  sitekey,
  multicurso,
  paidMode,
  userCanRegister
}
