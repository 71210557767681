let colorsMain ={
  color0:'transparent',
  color1:"#ffffff",
  // color secundario
  color3:"#ff9100",
  color13:'#ff9100',   //colores diferente para 24e y m360
  color4:"#01468c",
  color9:'#01468c',
  color12:'#01468c',

  color2:"#01356b",
  color5:'#01356b',
  color6:'#01356b',
  color7:'#01356b',

  color8:'#e5e5e5',
  color10:'#CCCCCC',
  color11:'#333333',
  color14:'rgba(255,255,255,0.5)',
  color15:'rgba(2,1,12,0.4)',

}

export {
  colorsMain
}
